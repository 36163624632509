import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LocationIcon from "../LocationIcon";
import Image from "next/image";
{/*import PhoneIcon from "../PhoneIcon"; */}
import closestShowroom from "../../utils/closestLocation";
import styled from "styled-components";
import { jsonData } from "../LocationsMap/_data";
import Spacer from '../../components/Content/Spacer';
import {
  Container,
  InfoText,
  bannerStyle,
  InfoTextLink,
  Column,
  ClearLeft,
  ClearRight,

} from "./styled";
import LoadingSpinner from "../LoadingSpinner";
import Link from "next/link";

function InfoBanner({ type, innerRef }) {
  const [loading, setLoading] = useState(false);
  const [showroom, setShowroom] = useState(null);
  const linkStyle = { color: '#ffffff' };
  const getCorrectLatLonAndStoreInLocalStorage = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLoading(true);
          const { latitude, longitude } = position.coords;
          const closestShowroomToUser = closestShowroom(latitude, longitude, jsonData.features);
          localStorage.setItem("locationFetched", JSON.stringify(closestShowroomToUser));
          setShowroom(closestShowroomToUser.properties);
          setLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    try {
      let counter = 0;
      const timesToRetry = 3; // Retry the check 3 times

      const checkLocalStorage = () => {
        if (counter <= timesToRetry) {
          counter += 1;
          let showroomInfo = localStorage.getItem("locationFetched");
          if (showroomInfo) {
            showroomInfo = JSON.parse(showroomInfo);
            setShowroom(showroomInfo.properties);
            setLoading(false);
            clearInterval(intervalId);
          }
        } else {
          clearInterval(intervalId);
        }
      };

      checkLocalStorage();

      const intervalId = setInterval(checkLocalStorage, 2000);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  return (
    <Container ref={innerRef} type={type}>

      <InfoText type={type}>Your Nearest Classy Closets Showroom:</InfoText>


      {/* <InfoTextLink style={{ textDecoration: "underline" }} type={type}>Phoenix, AZ</InfoTextLink> */}
      {loading && <LoadingSpinner width={"25px"} height={"25px"} />}
      {!loading && showroom && (
        <>
          <ClearLeft>
            <LocationIcon onClick={getCorrectLatLonAndStoreInLocalStorage} />
            <Link passHref href={`/locations/${showroom.city
              .trim()
              .toLowerCase()
              .replace(/ /g, "-")}`}>
              <InfoTextLink>
                {`${showroom.city},${showroom.state}`}
              </InfoTextLink>
            </Link>
          </ClearLeft>
          <ClearRight>
            <Image
              src="/icons/phone.svg"
              alt="Classy Closets Logo Mobile"
              width="20px"
              height="20px"
              loading="lazy"
              valign="bottom"
              quality={100} />

              <Link passHref href={`tel:${showroom.phoneFormatted}`}>
                
            <InfoTextLink>
                <a style={linkStyle}>{`${showroom.phoneFormatted}`}</a>
                </InfoTextLink>
              </Link>

          </ClearRight>
        </>
      )}
    </Container>
  );
}

InfoBanner.propTypes = {
  type: PropTypes.oneOf([bannerStyle.LIGHT, bannerStyle.DARK]),
};

InfoBanner.defaultProps = {
  type: bannerStyle.LIGHT,
}; 



export default InfoBanner;
