import styled from "styled-components";
import { Layout } from "antd";
const { Header, Content, Footer } = Layout;

const infoBannerHeight = 57;
const infoBannerHeightMobile = 60;
const infoBannerHeightMobileSmall = 82;

// export const LayoutContainer = styled(Layout)``;
export const LayoutContainer = styled.div``;

export const HeaderContainer = styled(Header)`
  display: block;
  height: ${({ theme }) => theme.layout.header.height}px;
  line-height: unset;
  background: ${({ theme }) => theme.colors.white};
  padding: 0;
  z-index: 99;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};

  position: fixed;
  width: 100%;
  top: 41px;
  left: 0;
  transform: ${({ isvisible }) =>
    isvisible === "yes" ? "translateY(0)" : "translateY(-100%)"};
  transition: transform 0.5s ease-in-out;

  @media only screen and (max-width: 768px) {
    height: ${({ theme }) => theme.layout.header.heightiPad + 16}px;
  }
  @media only screen and (max-width: 560px) {
    display: none;
  }
`;

export const HeaderContainerMobile = styled(Header)`
  height: ${({ theme }) => theme.layout.header.heightMobile + 18}px;
  line-height: unset;
  background: ${({ theme }) => theme.colors.white};
  padding: 0;
  z-index: 99;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};

  position: fixed;
  width: 100%;
  top: 52px;
  left: 0;

  @media only screen and (max-width: 560px) {
    top: 57px;
  }
`;

export const MainContainer = styled(Content)`
  padding-top: ${({ theme }) =>
    theme.layout.header.height + infoBannerHeight -14}px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding-top: ${({ theme }) =>
    theme.layout.header.heightiPad + infoBannerHeight}px;
  }
  @media only screen and (max-width: 560px) {
    padding-top: ${({ theme }) =>
    theme.layout.header.heightMobile + infoBannerHeightMobile}px;
  }
`;

export const ContainerWithMax = styled.div`
  width: 95%;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  margin: 0 auto;
  background-color: #ffffff;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterContainer = styled(Footer)`
  line-height: unset;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
`;
