function toRadians(degrees) {
  return degrees * Math.PI / 180;
}

function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371; // Earth's radius in kilometers

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const rLat1 = toRadians(lat1);
  const rLat2 = toRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rLat1) * Math.cos(rLat2) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
}

function findClosestShowroom(userLat, userLon, showrooms) {
  let closestDistance = Infinity;
  let closestShowroom = null;


  for (let i = 0; i < showrooms.length; i++) {
    const showroom = showrooms[i];
    if (showroom.properties.showroom !== 'yes') {
      continue;
    }

    const [showroomLon, showroomLat] = showroom.geometry?.coordinates;
    const distance = haversine(userLat, userLon, showroomLat, showroomLon);

    if (distance < closestDistance) {
      closestDistance = distance;
      closestShowroom = showroom;
    }
  }

  return closestShowroom;
}

export default findClosestShowroom;
