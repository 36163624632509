import React, { useRef, useEffect, useState, useContext } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import Footer from "../Footer";
import { IsDesktop, IsMobile } from "../ResponsiveContainers";
import { useModalContext } from "../../context/modalContext";
const Pop500 = dynamic(() => import("../../containers/Modal20Popup"));
const SchedulePopup = dynamic(() => import("../../containers/SchedulePopup"));
import Loading from "../Loading";
import InfoBanner from "../InfoBanner";
import {
  LayoutContainer,
  HeaderContainer,
  MainContainer,
  ContainerWithMax,
  FooterContainer,
  HeaderContainerMobile,
} from "./styled";
import { useHeaderContext } from "../../context/HeaderProvider";
import MainContainerContext from "../../context/MainContainer";
import Head from "next/head";
import { LocationContext } from "../../context/LocationContext"; // Import LocationContext
const AppLayout = ({ children, type, footerBG }) => {

  const location = useContext(LocationContext); // Use location context
  const { isVisible } = useHeaderContext();
  const wrapperRef = useRef();
  const [showCustomPopup, setShowCustomPopup] = useState(false);

  useEffect(() => {
    if (location) {
      const customCities = ["Chandler", "Scottsdale", "Dallas"]; // Custom cities list
      const city = location?.properties?.city?.trim().toLowerCase();
      console.log("City prop received:", city);
      console.log("Location context:", location);
  
      const normalizedCities = customCities.map(city => city.toLowerCase());
      console.log("Normalized customCities:", normalizedCities);
  
      if (normalizedCities.includes(city)) {
        console.log("City found in customCities, setting showCustomPopup to true");
        setShowCustomPopup(true);
      } else {
        console.log("City not found in customCities, setting showCustomPopup to false");
        setShowCustomPopup(false);
      }
    } else {
      console.log("Location not available in context.");
    }
  }, [location]);
  

  return (
    <>
      <LayoutContainer className="ant-layout">
        <Head>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Classy Closets",
              "url": "https://classyclosets.com",
              "logo": "https://classyclosets.com/images/logo/Classy-Closets-Email-Logo.png",
              "description": "We design, manufacture and install custom closets, cabinetry and home offices",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "1251 S Nelson Dr",
                "addressLocality": "Chandler",
                "addressRegion": "AZ",
                "postalCode": "85226",
                "addressCountry": "United States"
              }
            })}
          </script>
        </Head>
        {showCustomPopup ? (
          <Pop500 visible={true} />
        ) : (
          <SchedulePopup visible={true} />
        )}
        <InfoBanner role="alert" type="dark" />
        <MainContainerContext.Provider
          value={{ getContainer: () => wrapperRef.current || window }}
        >
          <HeaderContainer isvisible={isVisible ? "yes" : "no"}>
            <Header />
          </HeaderContainer>
          <IsMobile>
            <HeaderContainerMobile>
              <HeaderMobile />
            </HeaderContainerMobile>
          </IsMobile>
          <MainContainer>
            <ContainerWithMax ref={wrapperRef}>{children}</ContainerWithMax>
          </MainContainer>
          <FooterContainer>
            <Footer footerBG={footerBG} />
          </FooterContainer>
        </MainContainerContext.Provider>
      </LayoutContainer>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["noHeader", "noFooter"]),
  footerBG: PropTypes.string,
};

export default AppLayout;
