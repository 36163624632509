// A react component that renders an a basic, accessible 'get my location' icon for a user to click on. It takes an onClick function as a prop that will be called when the user clicks on the icon

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
width: 20px;
height: 20px;
vertical-align:bottom;
  &:hover {
    cursor: pointer;
  }
`;


const GetMyLocationIcon = ({ onClick }) => {
  return (
    <Wrapper title='Get My Location' role="button" tabIndex={0} aria-label="Find Nearest Showroom">
      <svg
        onClick={onClick}
        width="20"
        height="20"
        viewBox="0 0 32 32"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 0C10.4772 0 6 4.47715 6 10C6 17.5 16 32 16 32C16 32 26 17.5 26 10C26 4.47715 21.5228 0 16 0ZM16 14C13.2386 14 11 11.7614 11 9C11 6.23858 13.2386 4 16 4C18.7614 4 21 6.23858 21 9C21 11.7614 18.7614 14 16 14Z"
          fill="#FFFFFF"
        />
      </svg>
    </Wrapper>
  );
};

export default GetMyLocationIcon;