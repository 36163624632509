import React from "react";
import { withTheme } from "styled-components";
import Link from "next/link";

import { LogoDefault } from "../Logos";
import Menu from "../Menu";
import {
  Container,
  LogoSection,
  ContentWrap,
  CallUsBtnWrap,
  Wrapper,
  CustomPButton,
} from "./styled";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";

function Header({ theme }) {
  const [isDesktop, setIsDesktop] = React.useState(false);

  React.useEffect(() => {
    // Update the state each time the window is resized
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call the handleResize function to update the state initially
    handleResize();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickCallUs = () => {
    const phoneNumber = "18666427703";
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrap>
          <LogoSection>
            <Link href={"/"}>
              <a role="button" aria-label="Classy Closets logo, life organized, home page">
                <LogoDefault />
              </a>
            </Link>
            {isDesktop && <Menu />}
          </LogoSection>
        </ContentWrap>
      </Wrapper>
    </Container>
  );
}

export default withTheme(Header);
